import classNames from "classnames";
import { Fragment, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import _, { isError } from "lodash";
import BasicProfileInfo from "./basicProfileInfo";
import EmailAddresses from "./emailAddresses";
import PhoneNumbers from "./phoneNumbers";
import MainLayout from "./mainLayout";
import SecondMainLayout from "./secondMainLayout";
import getUserInfo from "../../swr/getUserInfo";
import {  BellIcon  } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import getUserPercentageComplete from "../../swr/getUserPercentageComplete";
import LogoUploader from "../../components/LogoUploader";
import toast from "react-hot-toast";
import Helmet from "react-helmet";
import TwoStepVerification from "./TwoStepVerification";
import Header from "../../components/Header";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import { motion } from "framer-motion";
import WhereYoureSignedIn from "./WhereYoureSignedIn";
import LoginHistory from "./LoginHistory";
import Loading from "../../components/loading";
import { KeyIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import { UserPlusIcon } from "@heroicons/react/20/solid";
import {
  UserIcon as Usericon,
  PresentationChartLineIcon,
  DocumentChartBarIcon,
} from "@heroicons/react/24/outline";
import {
  ChatBubbleLeftRightIcon
} from "@heroicons/react/24/outline";
interface menuItem {
  name: string | never;
  href?: string;
  icon: any;
  key: string;
  exact?: boolean;
  children?: menuItem[];
  index: number;
}

const MainPage = (props: any) => {
  const { percentage, isLoading, isError } = getUserPercentageComplete();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = window.innerWidth < 768;
  const queryParams = new URLSearchParams(location.search);
  const [navigation, setNavigation] = useState([
    {
      index: 1,
      name: t("identity.profile.AccountPreferences"),
      icon: <Usericon className={"h-4 w-4"} />,
      key: "AccountPreferences",
      exact: false,
      children: [
        {
          name: t("identity.profile.ProfileInformation"),
          href: "/reports/serviceCost",
          icon: "",
          key: "AccountPreferences/ProfileInformation",
          exact: true,
        },
      ],
    } as menuItem,

    {
      index: 2,
      name: t("identity.profile.SignInAndSecurity"),
      icon: <LockClosedIcon className={"h-4 w-4"} />,
      key: "SignInAndSecurity",
      exact: false,
      children: [
        {
          name: t("identity.profile.EmailAddresses"),
          href: "/",
          icon: "",
          key: "SignInAndSecurity/EmailAddresses",
          exact: true,
        },
        {
          name: t("identity.profile.PhoneNumbers"),
          href: "/",
          icon: "",
          key: "SignInAndSecurity/PhoneNumbers",
          exact: true,
        },
        {
          name: t("identity.profile.ChangePassword"),
          href: "/",
          icon: "",
          key: "SignInAndSecurity/ChangePassword",
          exact: true,
        },
        {
          name: t("identity.profile.WhereYoureSignedIn"),
          href: "/",
          icon: "",
          key: "SignInAndSecurity/WhereYoureSignedIn",
          exact: true,
        },
        {
          name: t("identity.profile.LoginHistory"),
          href: "/",
          icon: "",
          key: "SignInAndSecurity/LoginHistory",
          exact: true,
        },
        {
          name: t("identity.profile.TwoStepVerification"),
          href: "/",
          icon: "",
          key: "SignInAndSecurity/TwoStepVerification",
          exact: true,
        },
      ],
    } as menuItem,
    {
      index: 3,
      name: t("identity.profile.DataProfiles"),
      icon: <PresentationChartLineIcon className={"h-4 w-4"} />,
      key: "DataProfilesPublic",
      href: process.env.REACT_APP_PUBLIC_URL + "/profile/dataProfiles",
      exact: false,
    } as menuItem,
    {
      index: 4,
      name: t("identity.profile.Follow"),
      icon: <UserPlusIcon className={"h-4 w-4"} />,
      key: "FollowingPublic",
      href: process.env.REACT_APP_PUBLIC_URL + "/profile/following",
      exact: false,
    } as menuItem,
    {
      index: 5,
      name: t("identity.profile.RequestAccess"),
      icon: <KeyIcon className={"h-4 w-4"} />,
      key: "requestAccessPublic",
      href: process.env.REACT_APP_PUBLIC_URL + "/profile/requestAccess",
      exact: false,
    } as menuItem,

    {
      name: t("identity.profile.messaging"),
      icon: <ChatBubbleLeftRightIcon className={"h-4 w-4"} />,
      key: "messagingPublic",
      href: process.env.REACT_APP_PUBLIC_URL + "/profile/messaging",
      exact: false,
    } as menuItem,

    {
      name: t("identity.profile.Notifications"),
      icon: <BellIcon className={"h-4 w-4"} />,
      key: "NotificationsPublic",
      href: process.env.REACT_APP_PUBLIC_URL + "/profile/notifications",
      exact: false,
    } as menuItem,
  ]);

  
  const activeTab = queryParams.get("activeTab") ||( navigate[0] && navigate[0].key );
  const selectSecondTab = activeTab && activeTab.split("/").length == 2;
  const backItem =
    selectSecondTab &&
    !_.isEmpty(navigation.filter((n) => n.key == activeTab.split("/")[0]))
      ? navigation.filter((n) => n.key == activeTab.split("/")[0])[0]
      : navigation[0];

  const [activeMenu, setActiveMenu] = useState(
    activeTab && !_.isEmpty(navigation.filter((n) => activeTab.includes(n.key)))
      ? navigation.filter((n) => activeTab.includes(n.key))[0].index
      : -1
  );

  useEffect(() => {
    if (!activeTab) {
      navigate(`/account/profile?activeTab=AccountPreferences`);
    }
  }, []);

  const handleClick = (item) => {
    navigate(`/account/profile?activeTab=${item}`);
  };

  const { user, isLoadingUserApi, isErrorUserApi } = getUserInfo();

  useEffect(() => {
    console.log("login", user, isLoadingUserApi, isErrorUserApi);
    if (!isLoadingUserApi && (isErrorUserApi || !user)) {
      navigate("/Account/Login?ReturnUrl=/Account/Profile");
    }
  }, [isLoadingUserApi]);

  const changeProfilePic = async (profilePic: string) => {
    // console.log("profilePic", profilePic);
    // const client = new AccountClient(undefined, axiosInstance);
    // try {
    //   var res: any = null;
    //   res = await client.updateProfilePic(profilePic);
    //   if (res && res.succeeded) {
    //     toast.success("The profile picture update successfully!");
    //   } else {
    //     toast.error(_.first(res.errors) as any);
    //   }
    // } catch (error) {}
  };

  const renderBack = () => {
    return (
      <div className="shadow-[0_3px_5px_0_rgba(9,30,66,0.10)]">
        <div
          className="bg-white flex gap-1 border-b border-[#E5E7EB] border-solid items-center rounded-t-md p-3"
          onClick={() => navigate(`/account/profile?activeTab=${backItem.key}`)}
        >
          <svg
            className="float-right flex w-4 h-4 rotate-180 text-gray-900"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <div className="text-gray-900 text-md">{backItem.name}</div>
        </div>
      </div>
    );
  };

  return isLoadingUserApi || isErrorUserApi || !user ? (
    <Loading />
  ) : (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <Header />
      <div className="h-full sm:pt-20 pt-24 bg-[rgba(26,93,188,0.8]">
        <div
          className={classNames({
            "mx-auto lg:max-w-[80vw] max-w-[95vw] pb-10 md:py-12": !isMobile,
          })}
        >
          <div
            className={classNames("lg:grid lg:grid-cols-12 lg:gap-x-5", {
              "h-[100vh] bg-white": isMobile,
            })}
          >
            {!selectSecondTab || !isMobile ? (
              <div className="py-6 md:col-span-3 lg:col-span-4 2xl:col-span-3 md:py-0 md:px-0 mb-5">
                <div
                  className={classNames("space-y-1", {
                    "rounded-[15px]": !isMobile,
                  })}
                >
                  <div
                    className={classNames({
                      "shadow-[0_3px_5px_0_rgba(9,30,66,0.10)] rounded-[15px]":
                        !isMobile,
                    })}
                  >
                    <div
                      className={classNames("bg-white flex flex-col  p-6", {
                        "rounded-[15px]": !isMobile,
                      })}
                    >
                      <div className="w-full flex gap-4 flex-row">
                        <div className="w-fit flex flex-row justify-center items-center">
                          <LogoUploader
                            value={user.profilePicKey}
                            onChange={(d) => changeProfilePic(d)}
                            imgPath="/img/icon.png"
                            percentage={percentage}
                          />
                        </div>
                        <div className="flex flex-col justify-center tems-start py-3 gap-y-1">
                          <div className="flex-col justify-center items-start inline-grid">
                            <p className="text-base truncate text-left font-medium h-fit text-black">
                              {(user.firstName || "") +
                                " " +
                                (user.lastName || "")}
                            </p>
                            <p className="truncate font-light text-[14px] h-fit text-[#000]">
                              {user.userName}
                            </p>
                          </div>
                          {percentage && !isError && !isLoading ? (
                            <p className="truncate font-medium text-sm text-[#1A5DBC]">
                              {percentage}%{t("identity.profile.completed")}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <p className="mb-3 mt-3 truncate whitespace-pre-wrap text-[#828A96] text-left font-medium text-xs">
                        {t("identity.profile.PleaseCompleteYourDataEntires")}
                        <a
                          target="_blank"
                          className="text-[#1A5DBC] font-medium text-xs underline pl-0.5"
                          href={
                            process.env.REACT_APP_DATAPROVIDER_URL +
                            "/org/setupProfile"
                          }
                        >
                          {t("identity.profile.clickHere")}
                        </a>
                      </p>

                      <div className={classNames("flex flex-col w-[100%] ")}>
                        <div className="flex flex-col w-[100%]">
                          <nav className="flex flex-col flex-1 w-[100%]">
                            <ul
                              role="list"
                              className="w-[100%] flex flex-col flex-1 gap-y-7"
                            >
                              <li>
                                <ul role="list" className="space-y-1">
                                  {_.map(navigation, (item) => (
                                    <li key={item.name} className="relative ">
                                      <div
                                        className={classNames(
                                          activeTab && activeTab.includes(item.key)
                                            ? "text-[#1A5DBC] "
                                            : "hover:text-[#1A5DBC] text-black ",
                                          "group flex items-center py-2 text-base leading-6 font-medium  whitespace-nowrap cursor-pointer"
                                        )}
                                        onClick={() => {
                                          if (item.key.includes("Public")) {
                                            window.location.href = item.href;
                                          } else {
                                            if (activeMenu == item.index) {
                                              setActiveMenu(-1);
                                            } else {
                                              setActiveMenu(item.index);
                                            }
                                            navigate(
                                              `/account/profile?activeTab=${item.key}`
                                            );
                                          }

                                          // if (!item.href) {
                                          //   if (activeMenu > -1) {
                                          //     setActiveMenu(-1);
                                          //   } else {
                                          //     setActiveMenu(index);
                                          //   }
                                          // }
                                        }}
                                      >
                                        <span
                                          className={classNames(
                                            activeTab && activeTab.includes(item.key)
                                            ? "text-[#1A5DBC] "
                                            : "hover:text-[#1A5DBC] text-[#9AA9BF]",
                                            `shrink-0 mr-2`
                                          )}
                                          aria-hidden="true"
                                        >
                                          {item.icon}
                                        </span>
                                        {item.name}
                                        {!_.isEmpty(item.children) && (
                                          <motion.span
                                            className="icon-angle-down ml-auto text-xs "
                                            animate={{
                                              rotate:
                                                activeMenu === item.index
                                                  ? 180
                                                  : 0,
                                            }}
                                            initial={{
                                              rotate: 0,
                                            }}
                                            transition={{
                                              duration: 0.3,
                                            }}
                                          ></motion.span>
                                        )}
                                      </div>

                                      {!_.isEmpty(item.children) && (
                                        <motion.ul
                                          className="overflow-hidden"
                                          animate={{
                                            height:
                                              activeMenu === item.index
                                                ? "auto"
                                                : "0px",
                                          }}
                                          initial={{
                                            height: "0px",
                                          }}
                                          transition={{
                                            duration: 0.3,
                                          }}
                                        >
                                          {_.map(item.children, (sm) => {
                                            return (
                                              <li className="">
                                                <div
                                                  onClick={() =>
                                                    navigate(
                                                      `/account/profile?activeTab=${sm.key}`
                                                    )
                                                  }
                                                  className={classNames(
                                                    activeTab === sm.key
                                                      ? " text-blue-600"
                                                      : " hover:text-blue-600 ",
                                                    "group flex items-center p-2 2xl:text-sm text-xs leading-6 font-medium px-5 whitespace-nowrap cursor-pointer "
                                                  )}
                                                >
                                                  <span
                                                    className={classNames(
                                                      `2xl:text-xl text-lg shrink-0 mr-3 icon-${sm.icon}`
                                                    )}
                                                    aria-hidden="true"
                                                  />
                                                  <p className="text-black text-sm font-semibold"> {sm.name}</p>
                                                </div>
                                              </li>
                                            );
                                          })}
                                        </motion.ul>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {!isMobile || selectSecondTab ? (
              <>
                {isMobile && renderBack()}

                {activeTab == "AccountPreferences" ? (
                  <MainLayout handleClick={handleClick} />
                ) : activeTab.includes("ProfileInformation") ? (
                  <BasicProfileInfo
                    user={user}
                    handleClick={handleClick}
                    cancelAction={() =>
                      navigate(`/account/profile?activeTab=AccountPreferences`)
                    }
                  />
                ) : activeTab == "SignInAndSecurity" ? (
                  <SecondMainLayout handleClick={handleClick} user={user} />
                ) : activeTab.includes("EmailAddresses") ? (
                  <EmailAddresses />
                ) : activeTab.includes("PhoneNumbers") ? (
                  <PhoneNumbers hasPassword={user.hasPassword} />
                ) : activeTab.includes("TwoStepVerification") ? (
                  <TwoStepVerification hasPassword={user.hasPassword} />
                ) : activeTab.includes("ChangePassword") ? (
                  <ChangePassword />
                ) : activeTab.includes("WhereYoureSignedIn") ? (
                  <WhereYoureSignedIn />
                ) : activeTab.includes("LoginHistory") ? (
                  <LoginHistory />
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPage;
